<template>
    <div style="margin-top: 87px">
        <div v-html="txt"></div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            txt: "",
        };
    },
    async created() {
        // console.log(this.$route.params.id);
        const { data } = await axios.get("/api/brand_and_bottom/details", { params: { id: this.$route.params.id } });
        // console.log(data);
        if (!data || data == "<p><br></p>") return this.$router.push("/");
        this.txt = data;
    },
};
</script>

<style lang="less" scoped>
div {
    text-align: left;
}
</style>
